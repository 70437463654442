<template>
  <template v-if="deviceType === 'desktop'">
    <div class="container-fluid vh-100 fm-background-main-color">
      <InfoPage
        v-bind:title="'Something is no yes'"
        v-bind:message1="'Ale kaszana. Coś się zepsuło.'"
        v-bind:message2="'Spróbuj odświeżyć przeglądarkę czy coś.'"
        v-bind:image="'images/icons/anguished-face.svg'">
      </InfoPage>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="container vh-100 fm-background-main-color">
      <InfoPage
        v-bind:title="'Something is no yes'"
        v-bind:message1="'Ale kaszana. Coś się zepsuło.'"
        v-bind:message2="'Spróbuj odświeżyć przeglądarkę czy coś.'"
        v-bind:image="'images/icons/anguished-face.svg'">
      </InfoPage>
    </div>
  </template>

</template>

<script>
import InfoPage from '../components/InfoPage';

export default {
  name: 'UnknownError',
  components: { InfoPage },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    }
  }
};
</script>

<style scoped>

</style>
